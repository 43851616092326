<script setup>
import { ref, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { PermissionMap } from '@/utils/auth'
import { deleteAppreciation, getAppreciationList, getSortList } from '@/services/appreciation'
import { message } from 'ant-design-vue'

const router = useRouter()

const list = ref([])
const searchBarParams = ref('')
const tableState = reactive({
  selectedRowKeys: [],
  columns: [],
  loading: true,
  pagination: {
    showSizeChanger: true,
    current: 1,
    total: 1,
    pageSize: 10,
    showTotal: total => `共${total}条`,
    showQuickJumper: true
  }
})

const formState = reactive({
  name: null,
  sort: null
})

const pageState = reactive({
  firstLoading: true,
  sortOpts: [],
  allSorts: []
})

tableState.columns = [
  {
    title: '歌曲名称',
    dataIndex: 'song_name',
    ellipsis: true
  },
  {
    title: '歌曲编码',
    dataIndex: 'song_code',
    ellipsis: true
  },
  {
    title: '所属分类',
    dataIndex: 'category_id',
    ellipsis: true,
    slots: { customRender: 'sort' }
  },
  {
    title: '更新时间',
    dataIndex: 'updated_at',
    ellipsis: true
  },
  {
    title: '操作',
    key: 'action',
    fixed: 'right',
    slots: { customRender: 'action' }
  }
]

const handleSearch = () => {
  tableState.pagination.current = 1
  getList()
}

const getList = async (query) => {
  // 删除成功page没改
  tableState.loading = true
  const params = {}
  params.song_name = formState.song_name
  // params.category_id = formState.category_id?.at(-1) ?? null
  params.category_id = formState.category_id ? formState.category_id[formState.category_id.length - 1] : null
  params.per_page = tableState.pagination.pageSize
  params.page = tableState.pagination.current
  const { items, meta } = await getAppreciationList({ ...params, ...query })
  list.value = items
  tableState.pagination.total = meta.total
  tableState.pagination.current = meta.current_page
  tableState.loading = false
}

// 构造菜单树数据
const getTreeData = (pid, menus) =>
  menus
    .filter(item => item.pid === pid)
    .map(item => {
      if (menus.filter(menu => menu.pid === item.id).length === 0) {
        return { label: item.name, value: item.id }
      }
      return {
        label: item.name,
        value: item.id,
        children: getTreeData(item.id, menus)
      }
    })

const getSortData = async () => {
  const { items } = await getSortList()
  pageState.allSorts = items
  const data = getTreeData(0, items)
  pageState.sortOpts = data
}

const handleTableChange = (pag) => {
  console.log(pag)
  tableState.pagination.pageSize = pag.pageSize
  tableState.pagination.current = pag.current
  getList()
}

const handleReset = () => {
  formState.song_name = null
  formState.category_id = null
  tableState.pagination.current = 1
  getList()
}

const handleCreate = () => {
  router.push('./create/')
}

const handleJumpEdit = (id) => {
  router.push(`./edit/${id}`)
}

const handleJumpInfo = (id) => {
  router.push(`./info/${id}`)
}

const JumpConfig = () => {
  router.push('./config')
}

const handleDelete = (id) => {
  deleteAppreciation(id).then(res => {
    if (list.value.length === 1) {
      tableState.pagination.current = tableState.pagination.current === 1 ? 1 : tableState.pagination.current - 1
    }
    message.success('删除成功')
    getList()
  }).catch(err => {
    message.error('删除失败')
    console.error(err)
  })
}

onMounted(async () => {
  await Promise.all([
    getList(),
    getSortData()
  ])
  pageState.firstLoading = false
})
</script>

<template>
  <a-skeleton v-if="pageState.firstLoading" />
  <div v-show="!pageState.firstLoading">
    <div class="formDiv">
      <a-form layout="inline">
        <a-row style="width: 100%" :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="名称">
              <a-input @keyup.enter="getList" v-model:value="formState.song_name" />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="分类">
              <a-cascader
                change-on-select
                v-model:value="formState.category_id"
                :options="pageState.sortOpts"
                placeholder="请选择分类"
                expandTrigger="hover"
              />
            </a-form-item>
          </a-col>

          <a-col>
                <span
                  class="table-page-search-submitButtons"
                >
              <a-button type="primary" @click="handleSearch">查询</a-button>
              <a-button style="margin-left: 8px" @click="handleReset">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="tableDiv">
      <div class="tableOperator">
        <a-button
          type="primary"
          @click="JumpConfig"
          v-permission="PermissionMap.APPRECIATION_CONFIG"
        >
          赏析分类设置
        </a-button>

        <a-button
          @click="router.push('./import')"
          v-permission="PermissionMap.APPRECIATION_EDIT"
        >
          批量歌曲导入
        </a-button>

        <a-button
          type="primary"
          @click="handleCreate"
          v-permission="PermissionMap.APPRECIATION_EDIT"
        >
          新建赏析资源
        </a-button>
      </div>
      <a-table
        :dataSource="list"
        :columns="tableState.columns"
        rowKey="id"
        :pagination="tableState.pagination"
        :loading="tableState.loading"
        @change="handleTableChange"
        :scroll="{ x: 1300 }"
      >
        <!-- 操作栏 -->
        <template #action="{ record }">
            <span>
              <a @click="handleJumpInfo(record.id)">详情</a>
              <a-divider type="vertical" v-permission="PermissionMap.APPRECIATION_EDIT" />
              <a @click="handleJumpEdit(record.id)" v-permission="PermissionMap.APPRECIATION_EDIT">
                编辑
              </a>
<!--              <a-divider type="vertical" v-permission="PermissionMap.APPRECIATION_PUBLISH"/>-->
<!--              <a v-permission="PermissionMap.APPRECIATION_PUBLISH">上架</a>-->
              <a-divider type="vertical" v-permission="PermissionMap.APPRECIATION_DELETE" />
              <a-popconfirm
                title="确定删除该资源吗?"
                ok-text="删除"
                cancel-text="取消"
                @confirm="handleDelete(record.id)"
              >
                <a v-permission="PermissionMap.APPRECIATION_DELETE">
                  删除
                </a>
              </a-popconfirm>
            </span>
        </template>
        <template #sort="{ record }">
          <a-tag v-for="item in record.categories" :key="item.id">
            {{ item.name }}
          </a-tag>
        </template>
      </a-table>
    </div>
  </div>
</template>

<style lang="less" scoped>
.formDiv {
  background-color: white;
  margin: 30px 30px 0 30px;
  padding: 20px 38px;
}
.tableDiv {
  background-color: white;
  margin: 30px 30px 0 30px;
  padding: 20px 38px;
  .iconExtra {
    display: inline-block;
    font-size: 24px;
    margin: 2px 6px 0 0;
  }
  .cardTableDiv {
    padding-left: 20px;
  }
  .pagination {
    margin-top: 20px;
    text-align: right;
  }
}
.ant-col {
  margin-top: 16px;
}
.tableOperator {
  text-align: right;
  margin-bottom: 12px;
  button {
    margin: 0 10px;
  }
}

.tag_item_label {
  max-width: 100px;
  overflow: hidden; //超出一行文字自动隐藏
  text-overflow: ellipsis; //文字隐藏后添加省略号
  white-space: nowrap; //强制不换行
  cursor: default;
}
</style>
